import React, { Component } from "react";
import Select from "react-select";
import loggedInUserHelper from "../../helpers/logged-in-user-helper";
import NameInitialsAvatar from "./name-initials-avatar";
import utils from "../../helpers/utils";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import SnackbarView from "../../components/common/snackbar-view";
import footPrintsApi from "../../apis/footprints-api";
import mixConstants from "../../constants/mix-constants";
import GDrivePicker from "./gdrive-picker";
import LoadingOverlay from "react-loading-overlay";
import LinearProgress from "@material-ui/core/LinearProgress";
import Fade from "@material-ui/core/Fade";
import CreateFootPrintInfo from "../../components/common/create-footprint-info";
import Tooltip from "@material-ui/core/Tooltip";
import $ from "jquery";
import FileList from "./FileList";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

class CreateFootprintModalView extends Component {
  constructor() {
    super();

    this.state = {
      hashTag: "",
      hashTags: [],
      postDescription: "",

      importingGDriveFile: false,

      selectedClassGroup: null,
      commonFiles: [],
      users: [], // students or children
      usersWithFiles: [],
      showMessage: false,
      message: "",
      messageStatus: "error",
      submitted: false,

      postCreated: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.saveDraft = this.saveDraft.bind(this);
  }

  // component lifecycle methods

  componentDidMount() {
    this.loadJQuery();

    const { classesGroups, footPrintToUpdate } = this.props;

    if (loggedInUserHelper.isParents() && this.props.children) {
      const childrenList = this.mapUsersToCustomUserObject(this.props.children);
      this.setState({
        users: childrenList,
      });
    }
    if (footPrintToUpdate) {
      this.loadFootPrintToUpdate(footPrintToUpdate);
    } else if (classesGroups && classesGroups.length === 1) {
      const classGroup = classesGroups[0];
      this.handleClassGroupSelection(classGroup);
    }
    const savedDraft = localStorage.getItem("postDraft");
    if (savedDraft) {
      const draft = JSON.parse(savedDraft);
      this.setState({ ...draft });
    }
    //this.selectDefaultClassGroup();
  }

  // helper methods
  saveDraft() {
    if (
      !this.areAllCommonFilesUploaded() ||
      !this.areAllIndividualFilesUploaded()
    ) {
      this.setState({
        showMessage: true,
        messageStatus: "error",
        message: "Files uploading is in progress",
      });
      return;
    }
    this.setState({
      showMessage: false,
      messageStatus: "",
      message: "",
    });
    const draft = {
      ...this.state,
    };
    localStorage.setItem("postDraft", JSON.stringify(draft));
    this.setState({
      message: "Draft saved!",
      showMessage: true,
      messageStatus: "success",
    });
  }

  loadJQuery() {
    $(".popup-trigger").click();
  }

  loadFootPrintToUpdate(footPrint) {
    let { classesGroups, children } = this.props;

    let isParent = false;
    if (loggedInUserHelper.isParents() && children) {
      isParent = true;
      classesGroups = children;
    }

    let description = "";
    let hashTags = [];

    if (footPrint.description) {
      description = footPrint.description;
    }
    if (footPrint.tags) {
      hashTags = footPrint.tags.map((tag) => {
        return {
          id: utils.generateId(),
          text: tag,
        };
      });
    }

    if (footPrint.students && footPrint.students.length > 0) {
      if (isParent) {
        setTimeout(() => {
          const users = [...this.state.users];
          users.forEach((user) => {
            let files = [];
            const index = footPrint.students.findIndex((s) => {
              files = s.footprints;
              return s.student_id === user.userInfo.id;
            });
            if (index >= 0) {
              user.isChecked = true;
              user.files = files;
            }
          });
          this.setState({
            users: users,
          });
        }, 100);
      } else {
        const firstStudent = footPrint.students[0];

        const index = classesGroups.findIndex((cg) => {
          return (
            cg.students.findIndex((s) => {
              return s.id === firstStudent.student_id;
            }) >= 0
          );
        });
        if (index >= 0) {
          const classGroup = classesGroups[index];
          this.handleClassGroupSelection(classGroup);

          setTimeout(() => {
            const users = [...this.state.users];
            users.forEach((user) => {
              let files = [];
              const index = footPrint.students.findIndex((s) => {
                files = s.footprints;
                return s.student_id === user.userInfo.id;
              });
              if (index >= 0) {
                user.isChecked = true;
                user.files = files;
              }
            });
            this.setState({
              users: users,
            });
          }, 100);
        }
      }
    }

    this.setState({
      hashTags: hashTags,
      postDescription: description,
    });
  }

  makeCustomFileObject(file, isCommonFile) {
    if (file) {
      const ext = file.name.split(".").pop();
      const fileType = utils.fileType(ext);
      const fileIcon = utils.fileIcon(fileType);

      return {
        id: utils.generateId(),
        file: file,
        isCommonFile: isCommonFile,
        fileName: file.name,
        fileSize: file.size,
        filePreview: URL.createObjectURL(file),
        fileIcon: fileIcon,
        fileType: fileType,
        uploadPercentage: 0,
        uploaded: false,
        uploadedFileId: null,
        uploadedFileUrl: null,
      };
    }
    return null;
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      submitted: false,
      showMessage: false,
      message: "",
    });
  }

  handleKeyDown(e) {
    if (e.key === "Enter" && this.state.hashTag.trim() !== "") {
      this.addHashTagButtonClicked();
    }
  }

  addHashTagButtonClicked() {
    let hashTags = [...this.state.hashTags];
    const hashTag = {
      id: utils.generateId(),
      text: this.state.hashTag.replace(/\s/g, ""),
    };
    const index = hashTags.findIndex(
      (h) =>
        h.text.toLowerCase().trim() === hashTag.text.toLocaleLowerCase().trim()
    );
    if (index < 0 && hashTag.text.trim() !== "") {
      hashTags.push(hashTag);

      this.setState({
        hashTags: hashTags,
        hashTag: "",
      });
    }
  }

  async handleCommonLocalFileSelection(event) {
    let file = event.target.files[0];
    if (file === null || file === undefined) {
      return;
    }
    const ext = file.name.split(".").pop();
    if (file.type === "image/heif" || ext === "heif" || ext === "heic") {
      file = await utils.convertHeicIntoJpeg(file);
      if (!file) {
        this.setState({
          showMessage: true,
          message: mixConstants.limitations.CONVERSTION_FAILED,
          messageStatus: "error",
        });
      }
    }
    const isSupportedFile = utils.isSupportedFile(ext);

    if (!isSupportedFile) {
      this.setState({
        showMessage: true,
        message: mixConstants.limitations.UNSUPPORTED_FILE,
        messageStatus: "error",
      });
      return;
    }

    const fileSize = utils.bytesToMB(file.size);
    if (fileSize > mixConstants.limitations.UPLOAD_FILE_SIZE) {
      this.setState({
        showMessage: true,
        message: mixConstants.limitations.UPLOAD_FILE_SIZE_EXCEEDED_MESSAGE,
        messageStatus: "error",
      });
      return;
    }
    const customFileObject = this.makeCustomFileObject(file, true);

    let commonFiles = this.state.commonFiles;
    commonFiles.push(customFileObject);

    const userIndex = null;
    const fileId = customFileObject.id;

    this.setState({
      commonFiles: commonFiles,
      showMessage: false,
      message: "",
    });

    this.uploadCommonFile(customFileObject, fileId, userIndex);
  }

  async handleIndividualLocalFileSelection(event, user, i) {
    let file = event.target.files[0];
    if (file === null || file === undefined) {
      return;
    }
    const ext = file.name.split(".").pop();
    if (file.type === "image/heif" || ext === "heif" || ext === "heic") {
      file = await utils.convertHeicIntoJpeg(file);
      if (!file) {
        this.setState({
          showMessage: true,
          message: mixConstants.limitations.CONVERSTION_FAILED,
          messageStatus: "error",
        });
      }
    }
    const isSupportedFile = utils.isSupportedFile(ext);

    if (!isSupportedFile) {
      this.setState({
        showMessage: true,
        message: mixConstants.limitations.UNSUPPORTED_FILE,
        messageStatus: "error",
      });
      return;
    }

    const fileSize = utils.bytesToMB(file.size);
    if (fileSize > mixConstants.limitations.UPLOAD_FILE_SIZE) {
      this.setState({
        showMessage: true,
        message: mixConstants.limitations.UPLOAD_FILE_SIZE_EXCEEDED_MESSAGE,
        messageStatus: "error",
      });
      return;
    }
    const customFileObject = this.makeCustomFileObject(file, false);

    let userFiles = [...user.files];
    userFiles.push(customFileObject);

    const userIndex = i;
    const fileId = customFileObject.id;

    this.setState((state, props) => {
      state.users[i].files = userFiles;
      return {
        users: state.users,
        showMessage: false,
        message: "",
      };
    });

    this.uploadIndividualFile(customFileObject, fileId, userIndex);
  }

  handleCommonGDirveFilePickerEvent = (event, payload) => {
    if (event === "UNSUPPORTED_FILE") {
      this.setState({
        showMessage: true,
        message: mixConstants.limitations.UNSUPPORTED_FILE,
        importingGDriveFile: false,
        messageStatus: "error",
      });
      return;
    }
    if (event === "GOOGLE_DOCS_NOT_ALLOWED") {
      this.setState({
        showMessage: true,
        message: mixConstants.limitations.GOOGLE_DOCS_NOT_ALLOWED,
        importingGDriveFile: false,
        messageStatus: "error",
      });
      return;
    }
    if (event === "UPLOAD_FILE_SIZE_EXCEEDED_MESSAGE") {
      this.setState({
        showMessage: true,
        messageStatus: "error",
        message: mixConstants.limitations.UPLOAD_FILE_SIZE_EXCEEDED_MESSAGE,
        messageStatus: "error",
        importingGDriveFile: false,
      });
      return;
    }
    if (event === "START_REMOTE_PULL") {
      return void this.setState({ importingGDriveFile: true });
    }
    if (event === "SELECTED_FILES") {
      let commonFiles = [...this.state.commonFiles];

      if (payload.file && payload.fileInfo) {
        const fileName = payload.fileName;
        const fileMimeType = payload.file.type;
        const blob = payload.file;

        var file = new File([blob], fileName, {
          type: fileMimeType,
        });

        const customFileObject = this.makeCustomFileObject(file, true);
        commonFiles.push(customFileObject);

        const userIndex = null;
        const fileId = customFileObject.id;

        this.setState({
          importingGDriveFile: false,
          commonFiles: commonFiles,
          showMessage: false,
          message: "",
        });
        this.uploadCommonFile(customFileObject, fileId, userIndex);
      }
    }
  };

  handleIndividualGDirveFilePickerEvent = (event, payload, user, i) => {
    if (event === "UNSUPPORTED_FILE") {
      this.setState({
        showMessage: true,
        messageStatus: "error",
        message: mixConstants.limitations.UNSUPPORTED_FILE,
        importingGDriveFile: false,
      });
      return;
    }
    if (event === "GOOGLE_DOCS_NOT_ALLOWED") {
      this.setState({
        showMessage: true,
        messageStatus: "error",
        message: mixConstants.limitations.GOOGLE_DOCS_NOT_ALLOWED,
        importingGDriveFile: false,
      });
      return;
    }
    if (event === "UPLOAD_FILE_SIZE_EXCEEDED_MESSAGE") {
      this.setState({
        showMessage: true,
        messageStatus: "error",
        message: mixConstants.limitations.UPLOAD_FILE_SIZE_EXCEEDED_MESSAGE,
        importingGDriveFile: false,
      });
      return;
    }
    if (event === "START_REMOTE_PULL") {
      return void this.setState({ importingGDriveFile: true });
    }
    if (event === "SELECTED_FILES") {
      if (payload.file && payload.fileInfo) {
        const fileName = payload.fileName;
        const fileMimeType = payload.file.type;
        const blob = payload.file;

        var file = new File([blob], fileName, {
          type: fileMimeType,
        });

        const customFileObject = this.makeCustomFileObject(file, false);

        let userFiles = [...user.files];
        userFiles.push(customFileObject);

        const userIndex = i;
        const fileId = customFileObject.id;

        this.setState((state, props) => {
          state.users[i].files = userFiles;
          return {
            importingGDriveFile: false,
            users: state.users,
            showMessage: false,
            message: "",
          };
        });

        this.uploadIndividualFile(customFileObject, fileId, userIndex);
      }
    }
  };

  uploadCommonFile(customFileObject, fileId, userIndex) {
    footPrintsApi.uploadFootprint(
      customFileObject,
      fileId,
      userIndex,
      (uploadResponse) => {
        const fileIndex = this.state.commonFiles.findIndex(
          (f) => f.id === uploadResponse.fileId
        );
        if (uploadResponse.error) {
          console.log("Error: " + uploadResponse.error);
        }
        if (uploadResponse.uploadPercentage) {
          if (fileIndex >= 0) {
            this.setState((state, props) => {
              state.commonFiles[fileIndex].uploadPercentage =
                uploadResponse.uploadPercentage;
              return {
                commonFiles: state.commonFiles,
              };
            });
          }
        }
        if (uploadResponse.data) {
          if (fileIndex >= 0) {
            this.setState((state, props) => {
              state.commonFiles[fileIndex].uploaded = true;
              state.commonFiles[fileIndex].uploadedFileId = uploadResponse.data
                .id
                ? uploadResponse.data.id
                : null;
              state.commonFiles[fileIndex].uploadedFileUrl = uploadResponse.data
                .faith_footprint
                ? uploadResponse.data.faith_footprint
                : null;
              return {
                commonFiles: state.commonFiles,
              };
            });
          }
        }
      }
    );
  }

  uploadIndividualFile(customFileObject, fileId, userIndex) {
    footPrintsApi.uploadFootprint(
      customFileObject,
      fileId,
      userIndex,
      (uploadResponse) => {
        const fileIndex = this.state.users[
          uploadResponse.userIndex
        ].files.findIndex((f) => f.id === uploadResponse.fileId);

        if (uploadResponse.error) {
          console.log("Error: " + uploadResponse.error);
        }
        if (uploadResponse.uploadPercentage) {
          if (fileIndex >= 0) {
            this.setState((state, props) => {
              state.users[uploadResponse.userIndex].files[
                fileIndex
              ].uploadPercentage = uploadResponse.uploadPercentage;
              return {
                users: state.users,
              };
            });
          }
        }
        if (uploadResponse.data) {
          if (fileIndex >= 0) {
            this.setState((state, props) => {
              state.users[uploadResponse.userIndex].files[
                fileIndex
              ].uploaded = true;
              state.users[uploadResponse.userIndex].files[
                fileIndex
              ].uploadedFileId = uploadResponse.data.id
                ? uploadResponse.data.id
                : null;
              state.users[uploadResponse.userIndex].files[
                fileIndex
              ].uploadedFileUrl = uploadResponse.data.faith_footprint
                ? uploadResponse.data.faith_footprint
                : null;
              return {
                users: state.users,
              };
            });
          }
        }
      }
    );
  }

  areAllCommonFilesUploaded() {
    let notYetUploadedCommonFiles = this.state.commonFiles.filter((file) => {
      return file.uploaded === false;
    });
    return notYetUploadedCommonFiles.length === 0;
  }

  areAllIndividualFilesUploaded() {
    const allFiles = this.state.users.map((user) => {
      return user.files;
    });
    const mergedAllFiles = [].concat.apply([], allFiles);

    let notYetUploadedIndividualFiles = mergedAllFiles.filter((file) => {
      return file.uploaded === false;
    });
    return notYetUploadedIndividualFiles.length === 0;
  }

  handleClassGroupSelection(selectedClassGroup) {
    let students = selectedClassGroup.students;
    students = this.mapUsersToCustomUserObject(students);
    this.setState({ selectedClassGroup: selectedClassGroup, users: students });
  }

  selectDefaultClassGroup() {
    const { classesGroups } = this.props;

    if (
      classesGroups &&
      classesGroups.length > 0 &&
      this.state.selectedClassGroup === null
    ) {
      let students = classesGroups[0].students;
      students = this.mapUsersToCustomUserObject(students);

      this.setState({
        selectedClassGroup: classesGroups[0],
        users: students,
      });
    }
  }

  mapUsersToCustomUserObject(students) {
    const mappedStudents = students.map((student) => {
      return {
        isChecked: false,
        files: [],
        userInfo: student,
      };
    });
    return mappedStudents;
  }

  userCheckboxSelectionDidChange = (i) => (event) => {
    this.setState((state, props) => {
      state.users[i].isChecked = !state.users[i].isChecked;
      return {
        users: state.users,
      };
    });
  };

  handleAllUsersChecked = (event) => {
    let usersList = this.state.users;
    usersList.forEach((user) => (user.isChecked = event.target.checked));
    this.setState({ users: usersList });
  };

  areAllUsersChecked() {
    let selectedUsers = this.getSelectedUsers();
    if (selectedUsers.length > 0) {
      return selectedUsers.length === this.state.users.length;
    }
    return false;
  }

  getSelectedUsers() {
    if (this.state.users) {
      let selectedUsers = this.state.users.filter((user) => {
        return user.isChecked === true;
      });
      return selectedUsers;
    }
    return [];
  }

  renderHashTagsChipViews(hashTags) {
    return hashTags.map((hashTag) => {
      return (
        <Chip
          size="small"
          key={hashTag.id}
          label={hashTag.text}
          onDelete={() => this.deleteHashTagButtonClicked(hashTag)}
          color="primary"
          disabled={this.state.submitted}
        />
      );
    });
  }

  renderUsersList() {
    const { users, commonFiles } = this.state;

    const mappedUsersList = users.map((user, i) => {
      const isChecked = user.isChecked;
      const individualFiles = user.files;
      const userInfo = user.userInfo;
      const userId = userInfo.id;

      return (
        <Fade
          key={userId}
          in={true}
          style={{ transformOrigin: "0 0 0" }}
          timeout={mixConstants.constantValues.TRANSITION_DURATION}
        >
          <li>
            <label className="custom-checkbox">
              <input
                type="checkbox"
                defaultValue="checkedAll"
                checked={isChecked}
                onChange={this.userCheckboxSelectionDidChange(i)}
                disabled={this.state.submitted}
              />
              <span className="checkmark" />
            </label>
            <div className="list">
              {" "}
              <div className="btn-group upload-btns" role="group">
                {/* <small> Drag & Drop files here to upload, or </small> */}
                <Tooltip title="Photos / Videos" placement="bottom" arrow>
                  <button
                    type="button"
                    className="btn media-upload"
                    onClick={() => {
                      this[`individualLocalFileUploadButtonRef${i}`].click();
                    }}
                    disabled={this.state.submitted}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={17}
                      height="14.165"
                      viewBox="0 0 17 14.165"
                    >
                      <defs>
                        <style
                          dangerouslySetInnerHTML={{
                            __html:
                              ".a{fill:#fff;stroke:#fff;stroke-width:0.5px;}",
                          }}
                        />
                      </defs>
                      <g transform="translate(0.25 -43.729)">
                        <g transform="translate(0 43.979)">
                          <g transform="translate(0 0)">
                            <path
                              className="a"
                              d="M15.231,43.979H1.269A1.256,1.256,0,0,0,0,45.248V56.375a1.256,1.256,0,0,0,1.269,1.269H15.231A1.256,1.256,0,0,0,16.5,56.375V45.248A1.256,1.256,0,0,0,15.231,43.979Zm0,12.4H1.269L1.26,45.249H15.231V56.375Z"
                              transform="translate(0 -43.979)"
                            />
                          </g>
                        </g>
                        <g transform="translate(0.259 47.787)">
                          <g transform="translate(0 0)">
                            <path
                              className="a"
                              d="M14.075,162.361a.635.635,0,0,0-.864-.106l-5.183,3.808.752,1.023,4.7-3.453,6.684,8.064.977-.81Z"
                              transform="translate(-8.028 -162.132)"
                            />
                          </g>
                        </g>
                        <g transform="translate(8.616 48.443)">
                          <g transform="translate(0 0)">
                            <path
                              className="a"
                              d="M271.162,182.626a.635.635,0,0,0-.789,0l-3,2.39.79.993,2.61-2.077,3.455,2.734.787-1Z"
                              transform="translate(-267.369 -182.489)"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </button>
                </Tooltip>
                <input
                  // id="individualLocalFileInput"
                  accept={mixConstants.constantValues.ACCEPTABLE_FILES}
                  type="file"
                  ref={(ref) => {
                    this[`individualLocalFileUploadButtonRef${i}`] = ref;
                  }}
                  style={{ display: "none" }}
                  onChange={(e) =>
                    this.handleIndividualLocalFileSelection(e, user, i)
                  }
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                />
                <GDrivePicker
                  clientId={mixConstants.gdrivePicker.CLIENT_ID}
                  apiKey={mixConstants.gdrivePicker.API_KEY}
                  allowSharedDrives={true}
                  exportAsBlobs={true}
                  multiSelect={true}
                  downloadSelectedFiles={false}
                  onEvent={(event, payload) =>
                    this.handleIndividualGDirveFilePickerEvent(
                      event,
                      payload,
                      user,
                      i
                    )
                  }
                >
                  <Tooltip title="Google Drive" placement="top" arrow>
                    <button
                      type="button"
                      className="btn google-drive"
                      disabled={this.state.submitted}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.333"
                        height="14.291"
                        viewBox="0 0 16.333 14.291"
                      >
                        <defs>
                          <style
                            dangerouslySetInnerHTML={{
                              __html:
                                ".a{fill:#4caf50;}.b{fill:#ffc107;}.c{fill:#2196f3;}",
                            }}
                          />
                        </defs>
                        <g transform="translate(0 -1.5)">
                          <path
                            className="a"
                            d="M8.211,6.012,5.6,1.5,0,11.277l2.571,4.514Z"
                          />
                          <path
                            className="b"
                            d="M8.222,1.5l5.655,9.777H18.96L13.364,1.5Z"
                            transform="translate(-2.627)"
                          />
                          <path
                            className="c"
                            d="M6.381,15.867l-2.6,4.514H14.969l2.571-4.514Z"
                            transform="translate(-1.207 -4.59)"
                          />
                        </g>
                      </svg>
                    </button>
                  </Tooltip>
                </GDrivePicker>
              </div>
              <div className="userImage-name">
                <span className="user-avatar">
                  {userInfo.profile_picture ? (
                    <img
                      className="img-circle user-avatar"
                      src={userInfo.profile_picture}
                      alt="profile-pic"
                    />
                  ) : (
                    <NameInitialsAvatar
                      name={userInfo.name}
                      backgroundColor={
                        userInfo.bg_color
                          ? userInfo.bg_color
                          : utils.userAvatarBackgroundColor()
                      }
                    />
                  )}
                  <strong className="user-name"> {userInfo.name} </strong>
                </span>
                <ul className="uploaded-asstes scroll-div">
                  <DndProvider backend={HTML5Backend}>
                    {this.renderUserFiles(commonFiles, individualFiles, i)}
                  </DndProvider>
                </ul>
              </div>
            </div>
          </li>
        </Fade>
      );
    });
    return mappedUsersList;
  }

  renderUserFiles(commonFiles, individualFiles, userIndexInUsersArray) {
    const allFiles = [...commonFiles, ...individualFiles];
    if (
      this.state?.usersWithFiles?.[userIndexInUsersArray]?.files?.length > 0
    ) {
      // Step 1: Create a map of id order from array1
      const idOrder = this.state?.usersWithFiles?.[
        userIndexInUsersArray
      ]?.files.map((item) => item.id);
      // Step 2: Sort array2 based on the order of ids in array1
      allFiles.sort((a, b) => {
        const indexA = idOrder.indexOf(a.id);
        const indexB = idOrder.indexOf(b.id);

        // If one of the ids is not in idOrder, move it to the end
        if (indexA === -1) return 1; // a.id is not in idOrder, so move it to the end
        if (indexB === -1) return -1; // b.id is not in idOrder, so move it to the end

        // Otherwise, sort by their order in idOrder
        return indexA - indexB;
      });
    }
    this.state.usersWithFiles[userIndexInUsersArray] = {
      userId: this.state.users[userIndexInUsersArray]?.userInfo.id,
      files: allFiles,
    };
    const moveFile = (updatedFiles) => {
      this.state.usersWithFiles[userIndexInUsersArray].files = updatedFiles;
    };

    return (
      <FileList
        files={this.state?.usersWithFiles?.[userIndexInUsersArray]?.files}
        disabled={this.state.submitted}
        onDelete={(file) =>
          this.deleteFileButtonClicked(file, userIndexInUsersArray)
        }
        moveFile={moveFile}
        showMsg={(msg) => {
          this.setState({
            showMessage: true,
            messageStatus: "error",
            message: msg,
          });
        }}
      />
    );
  }

  isClassGroupSelectedHandler(option) {
    if (this.state.selectedClassGroup) {
      if (this.state.selectedClassGroup.id === option.id) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // buttons action methods

  closeModalViewButtonClicked() {
    if (this.props.onClose) {
      this.props.onClose();
      //$(".close").click();
    }
  }

  deleteHashTagButtonClicked(hashTag) {
    let hashTags = [...this.state.hashTags];
    const filteredHashTags = hashTags.filter((h) => {
      return h.id !== hashTag.id;
    });

    this.setState({
      hashTags: filteredHashTags,
    });
  }

  deleteFileButtonClicked(file, userIndexInUsersArray) {
    const isCommonFile = file.isCommonFile;
    const commonFiles = [...this.state.commonFiles];

    if (isCommonFile) {
      const filteredCommonFiles = commonFiles.filter((f) => {
        return f.id !== file.id;
      });
      this.setState({
        commonFiles: filteredCommonFiles,
      });
    } else {
      const userFiles = [...this.state.users[userIndexInUsersArray].files];
      const filteredIndividualFiles = userFiles.filter((f) => {
        return f.id !== file.id;
      });

      this.setState((state, props) => {
        state.users[userIndexInUsersArray].files = filteredIndividualFiles;
        return {
          users: state.users,
        };
      });
    }
  }

  hideMessage() {
    this.setState({
      showMessage: false,
      message: "",
    });
  }

  postButtonClicked() {
    const { hashTags, postDescription } = this.state;
    const { footPrintToUpdate } = this.props;
    const { TEACHER, SCHOOL_PRINCIPAL, PASTOR } = mixConstants.usersRoleName;
    const userRole = loggedInUserHelper.role().role_name.toLowerCase();

    if (postDescription.trim() === "") {
      this.setState({
        showMessage: true,
        messageStatus: "error",
        message: "Please enter the post description",
      });
      return;
    }
    if (this.getSelectedUsers().length === 0) {
      this.setState({
        showMessage: true,
        messageStatus: "error",
        message: "Please select the students",
      });
      return;
    }
    if (
      !this.areAllCommonFilesUploaded() ||
      !this.areAllIndividualFilesUploaded()
    ) {
      this.setState({
        showMessage: true,
        messageStatus: "error",
        message: "Files uploading is in progress",
      });
      return;
    }
    this.setState({
      showMessage: false,
      message: "",
      submitted: true,
    });

    const selectedUsers = this.getSelectedUsers();
    const studentsPost = selectedUsers.map((user) => {
      const userInfo = user.userInfo;
      const userId = userInfo.id;
      if (footPrintToUpdate && footPrintToUpdate.id) {
        const allFilesId = this.state.usersWithFiles
          .find((user) => user.userId === userId)
          ?.files?.map((file) => file?.id);
        return {
          student: userId,
          footprint: allFilesId,
        };
      } else {
        const allFilesId = this.state.usersWithFiles
          .find((user) => user.userId === userId)
          ?.files?.map((file) => file?.uploadedFileId);
        return {
          student: userId,
          footprint: allFilesId,
        };
      }
    });
    const post = {
      tags: hashTags.map((hashTag) => {
        return hashTag.text;
      }),
      description: postDescription,
      created_by: loggedInUserHelper.id(),
      student_post: studentsPost,
      school_class_id:
        userRole === TEACHER || userRole === SCHOOL_PRINCIPAL
          ? this.state.selectedClassGroup.id
          : null,
      church_group_id:
        userRole === PASTOR ? this.state.selectedClassGroup.id : null,
    };

    if (footPrintToUpdate && footPrintToUpdate.id) {
      footPrintsApi.updateFootPrint(post, footPrintToUpdate.id).then(
        (updatedFootPrint) => {
          this.setState({
            submitted: false,
            postCreated: true,
            showMessage: false,
            message: "",
          });

          setTimeout(() => {
            if (this.props.onFootPrintUpdated) {
              $(".close").click();
              this.props.onFootPrintUpdated(updatedFootPrint);
            }
          }, 100);
        },
        (error) => {
          this.setState({
            submitted: false,
            postCreated: false,
            showMessage: true,
            message: "Failed to update foot print",
            messageStatus: "error",
          });
        }
      );
    } else {
      footPrintsApi.createFootPrint(post).then(
        (createdFootPrint) => {
          this.setState({
            submitted: false,
            postCreated: true,
            showMessage: false,
            message: "",
          });

          setTimeout(() => {
            if (this.props.onFootPrintCreated) {
              $(".close").click();
              this.props.onFootPrintCreated(createdFootPrint);
            }
          }, 100);
        },
        (error) => {
          this.setState({
            submitted: false,
            postCreated: false,
            showMessage: true,
            messageStatus: "error",
            message: "Failed to create foot print",
          });
        }
      );
    }
    localStorage.removeItem("postDraft");
  }

  // render

  render() {
    const profilePicUrl = loggedInUserHelper.profilePicUrl();
    const profileBgColor = loggedInUserHelper.profileBackgroundColor();

    const fullName = loggedInUserHelper.fullName();
    const { classesGroups, footPrintToUpdate } = this.props;

    const classOrChurchGroupOfFootPrintToUpdate = classesGroups?.find(
      (obj) =>
        obj?.id === footPrintToUpdate?.school_class_id ||
        footPrintToUpdate?.church_group_id
    );

    const {
      users,
      showMessage,
      message,
      submitted,
      importingGDriveFile,
      selectedClassGroup,
      messageStatus,
    } = this.state;

    return (
      <div>
        <button
          type="button"
          className="btn btn-primary popup-trigger d-none"
          data-toggle="modal"
          data-target="#creatFootPrintModel"
        >
          Launch demo modal
        </button>

        <div
          className="modal fade quick-upload-container"
          id="creatFootPrintModel"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="creatFootPrintModelLabel"
          aria-hidden="true"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <LoadingOverlay
                active={importingGDriveFile}
                spinner
                className="overlay-main"
                text="Importing file..."
              ></LoadingOverlay>
              <div className="modal-header">
                <h5 className="modal-title" id="creatFootPrintModelLabel">
                  Upload
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.closeModalViewButtonClicked()}
                  disabled={submitted}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                {submitted ? (
                  <div className="linear-spinner" style={{ marginRight: 0 }}>
                    <LinearProgress />
                  </div>
                ) : (
                  ""
                )}
                <div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
                      <div className="new-post-container">
                        <div className="userImage-name">
                          <span className="user-avatar">
                            {profilePicUrl ? (
                              <img
                                src={profilePicUrl}
                                className="img-circle"
                                alt="profile-pic"
                              />
                            ) : (
                              <NameInitialsAvatar
                                name={fullName}
                                backgroundColor={profileBgColor}
                              />
                            )}
                          </span>
                          <span className="user-details">
                            <strong className="user-name"> {fullName} </strong>
                          </span>
                        </div>
                        <div className="new-post-area">
                          {/* <button className="blue-button"> Add Hashtag </button> */}

                          {/* start  */}
                          <div className="form-group user-input-area">
                            <TextField
                              id="outlined-multiline-static"
                              label="Hash Tag"
                              fullWidth
                              rows={5}
                              variant="outlined"
                              inputProps={{ maxLength: 30 }}
                              name="hashTag"
                              value={this.state.hashTag}
                              onChange={this.handleInputChange}
                              onKeyDown={this.handleKeyDown}
                              disabled={submitted}
                              className="tag-lable"
                              // pattern="[^\s]+"
                            />
                            <Fab
                              color="primary"
                              aria-label="add"
                              component="span"
                              onClick={() => this.addHashTagButtonClicked()}
                              disabled={submitted}
                            >
                              <AddIcon />
                            </Fab>
                          </div>

                          <div className="chip-box scroll-div">
                            {this.renderHashTagsChipViews(this.state.hashTags)}
                          </div>

                          {/* end  */}

                          <div className="form-group">
                            <textarea
                              className="form-control full-height-txtarea mt-2"
                              id="postDescriptionTextArea"
                              rows="4"
                              placeholder="Hey! Write something here to post"
                              name="postDescription"
                              value={this.state.postDescription}
                              onChange={this.handleInputChange}
                              disabled={submitted}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7">
                      <div className="selection-container scroll-div">
                        {!loggedInUserHelper.isParents() ? (
                          <div className="form-group mr-2">
                            <label htmlFor="selectclass">
                              {loggedInUserHelper.isTeacher() ||
                              loggedInUserHelper.isSchoolPrincipal()
                                ? "Select Class"
                                : loggedInUserHelper.isPastor() ||
                                  loggedInUserHelper.isChurchPrincipal()
                                ? "Select Group"
                                : ""}
                            </label>
                            <Select
                              className="select-bootstrap"
                              onChange={(selectedClassGroup) =>
                                this.handleClassGroupSelection(
                                  selectedClassGroup
                                )
                              }
                              getOptionLabel={(option) => `${option.name}`}
                              getOptionValue={(option) => `${option}`}
                              isOptionSelected={(option) =>
                                this.isClassGroupSelectedHandler(option)
                              }
                              options={classesGroups}
                              isSearchable={true}
                              isDisabled={
                                submitted || footPrintToUpdate ? true : false
                              }
                              noOptionsMessage={() => null}
                              placeholder={
                                selectedClassGroup?.name &&
                                footPrintToUpdate &&
                                classOrChurchGroupOfFootPrintToUpdate?.name
                                  ? classOrChurchGroupOfFootPrintToUpdate.name
                                  : loggedInUserHelper.isTeacher() ||
                                    loggedInUserHelper.isSchoolPrincipal()
                                  ? "Select Class"
                                  : loggedInUserHelper.isPastor() ||
                                    loggedInUserHelper.isChurchPrincipal()
                                  ? "Select Group"
                                  : ""
                              }
                              autoFocus={true}
                              //menuIsOpen={this.state.menuOpen}
                              //filterOption={this.customFilter}
                              //onInputChange={this.handleInputChange}
                              value={selectedClassGroup}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {users && users.length > 0 ? (
                          <div className="radio-container users-selection">
                            <div className="search-user">
                              <div className="container-title">
                                <label className="custom-checkbox">
                                  <input
                                    type="checkbox"
                                    onChange={this.handleAllUsersChecked}
                                    value="checkedAll"
                                    checked={this.areAllUsersChecked()}
                                    disabled={submitted}
                                  />
                                  <span className="checkmark"> </span>{" "}
                                  {loggedInUserHelper.isParents()
                                    ? "Children"
                                    : "Students"}
                                </label>
                              </div>
                              {/* <div className="input-group search-input">
                              <input
                                className="form-control"
                                type="search"
                                placeholder="Search footprints"
                                aria-label="Search"
                              />
                              <div className="search-action">
                                <button className="btn" type="submit">
                                  <i className="fa fa-search" />
                                </button>
                              </div>
                            </div> */}
                            </div>
                            <ul className="users-list-items">
                              {this.renderUsersList()}
                            </ul>
                          </div>
                        ) : (
                          <div className="empty-record">
                            {" "}
                            {loggedInUserHelper.isParents()
                              ? "No children"
                              : "No students"}{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer no-abs-footer">
                <div className="row">
                  <div className="col-xs-5 col-sm-8 col-md-8 col-lg-8 p-0">
                    {this.state.users.length > 0 ? (
                      <div className="footer-uploads">
                        <small className="upload-files">
                          {" "}
                          Upload Files for All
                          <CreateFootPrintInfo />
                        </small>
                        <div className="btn-group" role="group">
                          <button
                            type="button"
                            className="btn media-upload"
                            onClick={() => {
                              this.commonFileUploadButtonRef.click();
                            }}
                            disabled={submitted}
                          >
                            <Tooltip
                              title="Photos / Videos"
                              placement="top"
                              arrow
                            >
                              <span className="icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={17}
                                  height="14.165"
                                  viewBox="0 0 17 14.165"
                                >
                                  <defs>
                                    <style
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          ".a{fill:#fff;stroke:#fff;stroke-width:0.5px;}",
                                      }}
                                    />
                                  </defs>
                                  <g transform="translate(0.25 -43.729)">
                                    <g transform="translate(0 43.979)">
                                      <g transform="translate(0 0)">
                                        <path
                                          className="a"
                                          d="M15.231,43.979H1.269A1.256,1.256,0,0,0,0,45.248V56.375a1.256,1.256,0,0,0,1.269,1.269H15.231A1.256,1.256,0,0,0,16.5,56.375V45.248A1.256,1.256,0,0,0,15.231,43.979Zm0,12.4H1.269L1.26,45.249H15.231V56.375Z"
                                          transform="translate(0 -43.979)"
                                        />
                                      </g>
                                    </g>
                                    <g transform="translate(0.259 47.787)">
                                      <g transform="translate(0 0)">
                                        <path
                                          className="a"
                                          d="M14.075,162.361a.635.635,0,0,0-.864-.106l-5.183,3.808.752,1.023,4.7-3.453,6.684,8.064.977-.81Z"
                                          transform="translate(-8.028 -162.132)"
                                        />
                                      </g>
                                    </g>
                                    <g transform="translate(8.616 48.443)">
                                      <g transform="translate(0 0)">
                                        <path
                                          className="a"
                                          d="M271.162,182.626a.635.635,0,0,0-.789,0l-3,2.39.79.993,2.61-2.077,3.455,2.734.787-1Z"
                                          transform="translate(-267.369 -182.489)"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </span>
                            </Tooltip>
                          </button>
                          <input
                            id="commonLocalFileInput"
                            accept={
                              mixConstants.constantValues.ACCEPTABLE_FILES
                            }
                            type="file"
                            ref={(ref) =>
                              (this.commonFileUploadButtonRef = ref)
                            }
                            style={{ display: "none" }}
                            onChange={(e) =>
                              this.handleCommonLocalFileSelection(e)
                            }
                            onClick={(e) => {
                              e.target.value = null;
                            }}
                            disabled={submitted}
                          />
                          <GDrivePicker
                            clientId={mixConstants.gdrivePicker.CLIENT_ID}
                            apiKey={mixConstants.gdrivePicker.API_KEY}
                            allowSharedDrives={true}
                            exportAsBlobs={true}
                            multiSelect={true}
                            downloadSelectedFiles={false}
                            onEvent={this.handleCommonGDirveFilePickerEvent}
                          >
                            <button
                              type="button"
                              className="btn google-drive"
                              disabled={submitted}
                            >
                              <Tooltip
                                title="Google Drive"
                                placement="top"
                                arrow
                              >
                                <span className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16.333"
                                    height="14.291"
                                    viewBox="0 0 16.333 14.291"
                                  >
                                    <defs>
                                      <style
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            ".a{fill:#4caf50;}.b{fill:#ffc107;}.c{fill:#2196f3;}",
                                        }}
                                      />
                                    </defs>
                                    <g transform="translate(0 -1.5)">
                                      <path
                                        className="a"
                                        d="M8.211,6.012,5.6,1.5,0,11.277l2.571,4.514Z"
                                      />
                                      <path
                                        className="b"
                                        d="M8.222,1.5l5.655,9.777H18.96L13.364,1.5Z"
                                        transform="translate(-2.627)"
                                      />
                                      <path
                                        className="c"
                                        d="M6.381,15.867l-2.6,4.514H14.969l2.571-4.514Z"
                                        transform="translate(-1.207 -4.59)"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </Tooltip>
                            </button>
                          </GDrivePicker>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-xs-7 col-sm-4 col-md-4 col-lg-4 p-0 text-right">
                    <div className="hidden-div"></div>
                    <button
                      type="button"
                      className="btn blue-button mt-1 mr-1"
                      onClick={() => this.saveDraft()}
                      disabled={submitted}
                    >
                      Save as Draft
                    </button>
                    <button
                      type="button"
                      className="btn  blue-button mt-1 submit-btn"
                      onClick={() => this.postButtonClicked()}
                      disabled={submitted}
                    >
                      {footPrintToUpdate ? "Update" : "Post Now"}
                      {/* {submitted ? <Spinner /> : ""} */}
                    </button>
                  </div>
                </div>

                {showMessage && message ? (
                  <SnackbarView
                    status={messageStatus}
                    msg={message}
                    duration={3000}
                    onClose={() => this.hideMessage()}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateFootprintModalView;
